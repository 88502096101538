<template>
	<div class="ion-page">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<ion-header class="header">
			<ion-toolbar color="primary">
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<div class="logo">
					<img src="../assets/logo.png"/>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<div v-if="showStartButton" class="centered-in-page">
				<ion-button size="large" expand="block" @click="startQrCodeReader()">Registra presenza</ion-button>
			</div>
			<div v-if="showQrCodeReader" class="centered-in-page">
				<ion-grid>
					<ion-row> 
						<ion-col>
							<div class="qr-code-box">
								<qrcode-stream :camera="camera" :track="paintOutline" @decode="onDecode" @init="onInit">
									<div v-show="showScanConfirmationValid" class="scan-confirmation"></div>
									<div v-show="showScanConfirmationInvalid" class="scan-confirmation">
										<img src='../assets/error-icon.svg' alt="Error" width="128px" height="128px" />
									</div>
								</qrcode-stream>
								<p class="error" v-html="error"></p>
							</div>
						</ion-col>
					</ion-row>
					<ion-row> 
						<ion-col>
							<ion-button size="large" expand="block" @click="reset()">Chiudi</ion-button>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>
			<div v-if="showResponse" class="centered-in-page">
				<ion-grid>
					<ion-row> 
						<ion-col>
							<div v-if="responseSuccess" class="response">
								<ion-icon name="checkmark-circle" class="icon-response" :class="{'success': presenza.new, 'skipped': !presenza.new}"></ion-icon>
								<p class="message-response" :class="{'success': presenza.new, 'skipped': !presenza.new}" v-html="responseMsg"></p>
								<ion-card>
									<ion-card-content text-left>
										<p>
											Nome:<br>
											<b>{{guest.name}}</b>
										</p>
										<p>
											Cogome:<br>
											<b>{{guest.surname}}</b>
										</p>
										<p>
											Data:<br>
											<b>{{presenza.formatted_date}}</b>
										</p>
										<p>
											Luogo:<br>
											<b>{{guest.sedi.indirizzo + ' ' + guest.sedi.num_civico + ', ' + guest.sedi.comune.des_luo + " (" + guest.sedi.comune.s_prv + ") dell'ente " + guest.sedi.azienda.denominazione}}</b>
										</p>
									</ion-card-content>
								</ion-card>
							</div>
							<div v-if="!responseSuccess" class="response">
								<ion-icon name="close" class="error icon-response"></ion-icon>
								<p class="error message-response" v-html="responseMsg"></p>
							</div>
						</ion-col>
					</ion-row>
					<ion-row> 
						<ion-col>
							<ion-button size="large" expand="block" @click="startQrCodeReader()">Indietro</ion-button>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>
			<div v-if="showMinors" class="centered-in-page">
				<ion-grid>
					<ion-row> 
						<ion-col>
							<p><b>Selezionare per quali famigliari si desidera salvare la presenza.</b></p>
							<ion-card>
								<ion-card-content v-if="guest.presenza && guest.presenza.presente" text-left>
									<p>
										{{guest.name}}
										{{guest.surname}}
									</p>
									<p>
										<ion-icon ion-icon name="checkmark-circle" class="minors-icon-success"></ion-icon>
										{{guest.presenza.formatted_date}}
									</p>
								</ion-card-content>
								<ion-card-content v-else>
									<p>
										{{guest.name}}
										{{guest.surname}}
									</p>
									<p>
										<input type="checkbox" class="presenza-check" @change="changeGuestToRegister($event, guest.id)"> Segnare la presenza di questo ospite
									</p>
								</ion-card-content>
							</ion-card>
							<p class="minors-message">Sono stati trovati i seguenti famigliari minorenni.</p>
							<ion-card v-for="(minor, index) in minors" v-bind:key="index">
								<ion-card-content v-if="minor.presenza && minor.presenza.presente" text-left>
									<p>
										{{minor.name}}
										{{minor.surname}}
									</p>
									<p>
										<ion-icon ion-icon name="checkmark-circle" class="minors-icon-success"></ion-icon>
										{{minor.presenza.formatted_date}}
									</p>
								</ion-card-content>
								<ion-card-content v-else>
									<p>
										{{minor.name}}
										{{minor.surname}}
									</p>
									<p>
										<input type="checkbox" class="presenza-check" @change="changeGuestToRegister($event, minor.id)"> Segnare la presenza di questo famigliare
									</p>
								</ion-card-content>
							</ion-card>
						</ion-col>
					</ion-row>
					<ion-row> 
						<ion-col>
							<ion-button :disabled="!guestsToRegister.length" size="large" expand="block" @click="saveMinors()">Salva</ion-button>
							<ion-button size="large" expand="block" @click="startQrCodeReader()">Indietro</ion-button>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>
		</ion-content>
	</div>
</template>

<script>
import axios from 'axios';

// Import component
import Loading from 'vue-loading-overlay';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-select/dist/vue-select.css";

// Import QR code reader
import { QrcodeStream } from 'vue-qrcode-reader';
// Import geolocation
import VueGeolocation from 'vue-browser-geolocation';

export default {
	name: 'HomePage',
	props: {
		msg: String
	},
	data: function(){
		return {
			isLoading: false,
			fullPage: true,
			firstInit: true,
			showStartButton: true,
			showQrCodeReader: false,
			showResponse: false,
			showMinors: false,
			timer: null,
			camera: 'off',
			showScanConfirmationValid: false,
			showScanConfirmationInvalid: false,
			validCode: undefined,
			error: '',
			responseSuccess: false,
			responseMsg: '',
			codeContent: null,
			guest: null,
			presenza: null,
			minors: null,
			guestsToRegister: []
		}
	},

	components: {
		Loading,
		QrcodeStream
	},

	mounted: function(){
		var token = localStorage.getItem("user_token");

		if(token === null || token == ''){
			location.href = '#/login';
		}
	},

	methods: {

		startQrCodeReader() {
			this.isLoading = true;
			this.error = '';
			this.showStartButton = false;
			this.showQrCodeReader = true;
			this.showResponse = false;
			this.showMinors = false;
			this.responseSuccess = false;
			this.responseMsg = '';
			this.codeContent = null;
			this.guest = null;
			this.presenza = null;
			this.minors = null;
			this.guestsToRegister = [];
			this.unpause();
		},

		async onDecode (content) {
			this.error = "";
			var contentArray = content.split('-');
			contentArray.pop()
			if (
				contentArray.length === 2 && 
				contentArray.every(function(element) {return /^\d+$/.test(element) && parseInt(element) > 0;})
			) {
				this.validCode = true;
				this.isLoading = true;
				this.codeContent = content;

				this.pause();

				this.getGuestFamilyMinors();
			} else {
				this.error = "QR Code non valido.";
				this.validCode = false;

				this.pause();
				await this.timeout(500);
				this.unpause();
			}
		},

		unpause () {
			this.camera = 'auto';
			this.validCode = undefined;
			this.timer = setTimeout(() =>{ 
				this.showQrCodeReader = false;
				this.showStartButton = true;
			}, 30000);
		},

		pause () {
			this.camera = 'off';
			clearTimeout(this.timer);
		},

		timeout (ms) {
			return new Promise(resolve => {
				window.setTimeout(resolve, ms)
			})
		},

		async onInit (promise) {
			if (this.firstInit) {
				this.isLoading = true;
				this.firstInit = false;
			}
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') {
					this.error = "ERROR: you need to grant camera access permisson"
				} else if (error.name === 'NotFoundError') {
					this.error = "ERROR: no camera on this device"
				} else if (error.name === 'NotSupportedError') {
					this.error = "ERROR: secure context required (HTTPS, localhost)"
				} else if (error.name === 'NotReadableError') {
					this.error = "ERROR: is the camera already in use?"
				} else if (error.name === 'OverconstrainedError') {
					this.error = "ERROR: installed cameras are not suitable"
				} else if (error.name === 'StreamApiNotSupportedError') {
					this.error = "ERROR: Stream API is not supported in this browser"
				}
			} finally {
				this.showScanConfirmationValid = this.camera === "off" && this.validCode === true;
				this.showScanConfirmationInvalid = this.camera === "off" && this.validCode === false;
				this.isLoading = false;
			}
		},	

		reset () {
			this.showStartButton = true;
			this.showQrCodeReader = false;
			this.showResponse = false;
			this.showMinors = false;
			this.firstInit = true;
			this.error = '';
			this.responseSuccess = false;
			this.responseMsg = '';
			this.codeContent = null;
			this.guest = null;
			this.presenza = null;
			this.minors = null;
			this.guestsToRegister = [];
		},

		paintOutline (detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

				ctx.strokeStyle = "red";

				ctx.beginPath();
				ctx.moveTo(firstPoint.x, firstPoint.y);
				for (const { x, y } of otherPoints) {
				ctx.lineTo(x, y);
				}
				ctx.lineTo(firstPoint.x, firstPoint.y);
				ctx.closePath();
				ctx.stroke();
			}
		},

		async getGuestFamilyMinors () {
			var token = localStorage.getItem("user_token");

			axios.get(process.env.VUE_APP_BASE_URL + 'webApp/getGuestFamilyMinors', {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(async response => {
				if (response.data.body.response == 'OK') {
					// Ci sono minori in famiglia
					this.guest = response.data.body.data.guest;
					this.minors = response.data.body.data.minors;
					this.showQrCodeReader = false;
					this.showMinors = true;
					this.isLoading = false;
				} else if (response.data.body.response == 'KO') {
					// Non ci sono minori in famiglia
					this.savePresenza();
				} else if (response.data.body.response == 'TOKEN-KO') {
					localStorage.setItem("user_token", "");
					location.href = '#/login';
				}
			})
			.catch(async e => {
				// eslint-disable-next-line
				console.log(e);

				this.unpause();
				this.isLoading = false;
			});
		},

		async savePresenza () {
			this.isLoading = true;

			let params = new URLSearchParams();
			params.append('qr_code', this.codeContent);

			VueGeolocation.getLocation()
			.then(coordinates => {
				params.append('latitude', coordinates.lat);
				params.append('longitude', coordinates.lng);

				this.callSavePresenza(params);
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);

				params.append('latitude', '');
				params.append('longitude', '');

				this.callSavePresenza(params);
			});
		},

		async saveMinors () {
			this.isLoading = true;

			let params = new URLSearchParams();
			params.append('qr_code', this.codeContent);
			params.append('guests_to_register', JSON.stringify(this.guestsToRegister));

			VueGeolocation.getLocation()
			.then(coordinates => {
				params.append('latitude', coordinates.lat);
				params.append('longitude', coordinates.lng);

				this.callSavePresenza(params);
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);

				params.append('latitude', '');
				params.append('longitude', '');

				this.callSavePresenza(params);
			});
		},

		async callSavePresenza (params) {
			this.isLoading = true;

			var token = localStorage.getItem("user_token");
			var client_id = localStorage.getItem("client_id");

			axios.post(process.env.VUE_APP_BASE_URL + 'webApp/savePresenza', params, {
				headers: {
					'Authorization': 'Bearer ' + token,
					'X-Client-Id': client_id
				}
			})
			.then(async response => {
				if (response.data.body.response == 'OK') {
					this.guest = response.data.body.data.guest;
					this.presenza = response.data.body.data.presenza;
					this.responseMsg = response.data.body.msg;
					this.responseSuccess = true;
				} else if (response.data.body.response == 'KO') {
					this.responseMsg = response.data.body.msg;
					this.responseSuccess = false;
				} else if (response.data.body.response == 'TOKEN-KO') {
					localStorage.setItem("user_token", "");
					location.href = '#/login';
				}
				this.showQrCodeReader = false;
				this.showMinors = false;
				this.guestsToRegister = [];
				this.showResponse = true;
				this.isLoading = false;
			})
			.catch(async e => {
				// eslint-disable-next-line
				console.log(e);

				this.unpause();
				this.showMinors = false;
				this.guestsToRegister = [];
				this.isLoading = false;
			});
		},

		changeGuestToRegister(event, id) {
			if (event.target.checked) {
				this.guestsToRegister.push(id);
			} else {
				let index = this.guestsToRegister.indexOf(id);
				this.guestsToRegister.splice(index, 1);
			}
		}
	}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	ion-content {
		margin-top: 2em;
		margin-left: 1em;
		margin-right: 1em;
    }
	ion-card-content {
		color: #000000;
	}
	.error {
		font-weight: bold;
		color: #F44336;
	}
	.success {
		font-weight: bold;
		color: #4CAF50;
	}
	.skipped {
		font-weight: bold;
		color: #A1A1A1;
	}
	ion-footer{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
	}
	.centered-in-page {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.scan-confirmation {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, .8);
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
	}
	.response {
		text-align: center;
	}
	.response ion-card-content p {
		font-size: 16px;
		margin-bottom: 10px;
	}
	.icon-response {
		font-size: 108px;
	}
	.message-response {
		font-size: 28px;
	}
	.minors-icon-success {
        font-weight: bold;
		color: #4CAF50;
        position: relative;
        top: 2px;
    }
    .presenza-check {
        position: relative;
        top: 2px;
    }
    .minors-message {
        margin: 10px;
    }
</style>
