import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';

import Vue from 'vue'
import Ionic from '@ionic/vue';
import router from './router';

import App from './App.vue';
import './registerServiceWorker';
Vue.config.productionTip = false;

//App version
Vue.prototype.$appVersion = '1.1.0';

Vue.use(Ionic);

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
