<template>
	<div class="ion-page">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<ion-header class="header">
			<ion-toolbar color="primary">
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<div class="logo">
					<img src="../assets/logo.png"/>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<div class="ion-text-center">
				<h1>Profilo</h1>
			</div>
			<section>
				<ion-grid>
					<ion-row class="row-readonly" color="primary" justify-content-center> 
						<ion-col align-self-center size-md="6" size-lg="5" size-xs="12">
							<ion-item>
								<ion-input readonly type="text" :value="user.name" placeholder="Nome"></ion-input>
							</ion-item>
							<ion-item>
								<ion-input readonly type="text" :value="user.surname" placeholder="Cognome"></ion-input>
							</ion-item>
							<ion-item>
								<ion-input readonly type="text" :value="address" placeholder="Indirizzo"></ion-input>
							</ion-item>
							<ion-item>
								<ion-input readonly type="text" :value="user.username" placeholder="Username"></ion-input>
							</ion-item>
						</ion-col>
					</ion-row>
					<ion-row class="row-editable" color="primary" justify-content-center> 
						<ion-col align-self-center size-md="6" size-lg="5" size-xs="12">
							<ion-item>
								<ion-input type="password" :value="password" @ionInput="password = $event.target.value;" placeholder="Password"></ion-input>
							</ion-item>
							<ion-item>
								<ion-input type="password" :value="repeatPassword" @ionInput="repeatPassword = $event.target.value;" placeholder="Ripeti password"></ion-input>
							</ion-item>
						</ion-col>
					</ion-row>
				</ion-grid>
			</section>
		</ion-content>

		<ion-footer no-border>
			<ion-button size="large" :disabled="invalid" expand="block" @click="saveGuest()">Aggiorna password</ion-button>
		</ion-footer>
	</div>
</template>

<script>
import axios from 'axios';

// Import component
import Loading from 'vue-loading-overlay';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-select/dist/vue-select.css";

export default {
	name: 'UserPage',

	data (){
		return {
			isLoading: false,
			fullPage: true,
			user: {
				id: null,
				name: null,
				surname: null,
				username: null,
				sedi: {
					indirizzo: null,
					comune: {
						des_luo: null,
						s_prv: null
					}
				}
			},
			password: null,
			repeatPassword: null
		}
	},

	components: {
		Loading
	},

	mounted (){
		var token = localStorage.getItem("user_token");

		if (token === null || token == '') {
			location.href = '#/login';
		}

		this.init();
	},

	methods: {

		init () {
			this.isLoading = true;

			var token = localStorage.getItem("user_token");

			axios.get(process.env.VUE_APP_BASE_URL + 'webApp/getGuest', {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(response => {
				if (response.data.body.response == 'OK') {
					this.user = response.data.body.data; 
				} else if (response.data.body.response == 'TOKEN-KO') {
					localStorage.setItem("user_token", "");
					location.href = '#/login';
				}
				this.isLoading = false;
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);
				alert("Errore nell'inizializzazione dei dati.");
				this.isLoading = false;
			});
        },

		saveGuest () {
			this.isLoading = true;

			var token = localStorage.getItem("user_token");

			let params = new URLSearchParams();
			params.append('id', this.user.id);
			params.append('password', this.password);

			axios.post(process.env.VUE_APP_BASE_URL + 'webApp/saveGuest', params, {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(response => {
				// JSON responses are automatically parsed.
				// eslint-disable-next-line
				//console.log(response);
				
				if (response.data.body.response == 'OK') {
					this.password = null;
					this.repeatPassword = null;
				} else if (response.data.body.response == 'KO') {
					alert(response.data.body.msg);
				} else if (response.data.body.response == 'TOKEN-KO') {
					localStorage.setItem("user_token", "");
					location.href = '#/login';
				}
				
				this.isLoading = false;
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);
				
				alert('Errore nell\'autenticazione.');

				this.isLoading = false;
			});
		}
	},

	computed: {
		invalid() {
			return !this.password || !this.repeatPassword || this.password !== this.repeatPassword;
		},

		address() {
			if (this.user.id) {
				return this.user.sedi.indirizzo + ' ' + this.user.sedi.num_civico + ', ' + this.user.sedi.comune.des_luo + " (" + this.user.sedi.comune.s_prv + ")";
			} else {
				return '';
			}
			
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	section {
		margin-top: 2em;
		margin-left: 1em;
		margin-right: 1em;
    }
	ion-card-content {
		color: #000000;
	}
	.error {
		margin-top: 10px;
		font-weight: bold;
		color: red;
	}
	.row-editable {
		margin-top: 30px;
	}
	ion-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
	}
</style>
