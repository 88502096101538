import Vue from 'vue';
import Router from 'vue-router';

import LoginPage from '../login/LoginPage.vue';
import HomePage from '../home/HomePage.vue';
import UserPage from '../user/UserPage.vue';

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: LoginPage,
        },
        {
            path: '/',
            name: 'Home',
            component: HomePage,
        },
        {
            path: '/user',
            name: 'User',
            component: UserPage,
        }
    ],
});